// n is number of stars required
@function multiple-box-shadow($n) {
  $value: '#{random(2500)}px #{random(2500)}px #FFFFFF70'; // height

  @for $i from 2 through $n {
    $value: '#{$value} , #{random(2500)}px #{random(2500)}px #FFFFFF70';
  }
  @return unquote($value);
}

$shadows-small: multiple-box-shadow(700);
$shadows-medium: multiple-box-shadow(200);
$shadows-big: multiple-box-shadow(100);

$speed-small: 150s;
$speed-medium: 100s;
$speed-big: 50s;

.stars1 {
  width: 1px;
  height: 1px;
  border-radius: 50%;
  animation: animStar $speed-small linear infinite;

  &:after {
    content: ' ';
    position: absolute;
    top: 100%;
    width: 1.5px;
    height: 1.5px;
    border-radius: 50%;
    background: transparent;
    box-shadow: $shadows-small;
  }
}

.stars2 {
  width: 2px;
  height: 2px;
  background: transparent;
  border-radius: 50%;
  box-shadow: $shadows-medium;
  animation: animStar $speed-medium linear infinite;
}

.stars3 {
  width: 2px;
  height: 2px;
  background: transparent;
  border-radius: 50%;
  box-shadow: $shadows-big;
  animation: animStar $speed-big linear infinite;
}

.hearts1 {
  animation: animHeart $speed-medium linear infinite;
}

.hearts1 svg {
  animation: 7s tilt-shaking 0s infinite;
}

.hearts2 {
  transform: rotate(-5deg);
  animation: animHeart $speed-small linear infinite;
}

.hearts2 svg {
  animation: 5s tilt-shaking 1s infinite;
}

.hearts3 {
  transform: rotate(-25deg);
  animation: animHeart $speed-medium linear infinite;
}

.hearts3 svg {
  animation: 8s tilt-shaking 0.6s infinite;
}

.hearts4 {
  transform: rotate(-20deg);
  animation: animHeart $speed-small linear infinite;
}

.hearts4 svg {
  animation: 8s tilt-shaking 0.3s infinite;
}

.hearts5 {
  transform: rotate(-5deg);
  animation: animHeart $speed-medium linear infinite;
}

.hearts5 svg {
  animation: 6s tilt-shaking 0s infinite;
}

.hearts6 {
  transform: rotate(15deg);
  animation: animHeart $speed-small linear infinite;
}

.hearts6 svg {
  animation: 4s tilt-shaking 0s infinite;
}

@keyframes animStar {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(-2500px);
  }
}

@keyframes animHeart {
  from {
    transform: translateY(400px);
  }
  to {
    transform: translateY(-2500px);
  }
}

@-webkit-keyframes animate-svg-stroke-3 {
  0% {
    stroke-dashoffset: 446.23046875px;
    stroke-dasharray: 446.23046875px;
  }

  100% {
    stroke-dashoffset: 892.4609375px;
    stroke-dasharray: 446.23046875px;
  }
}

@keyframes animate-svg-stroke-3 {
  0% {
    stroke-dashoffset: 446.23046875px;
    stroke-dasharray: 446.23046875px;
  }

  100% {
    stroke-dashoffset: 892.4609375px;
    stroke-dasharray: 446.23046875px;
  }
}

.heart1-path {
  -webkit-animation: animate-svg-stroke-3 9s ease 4s both;
  animation: animate-svg-stroke-3 9s ease 4s both;
}

@-webkit-keyframes animate-svg-stroke-5 {
  0% {
    stroke-dashoffset: 1207.333984375px;
    stroke-dasharray: 1207.333984375px;
  }

  100% {
    stroke-dashoffset: 0;
    stroke-dasharray: 1207.333984375px;
  }
}

@keyframes animate-svg-stroke-5 {
  0% {
    stroke-dashoffset: 1207.333984375px;
    stroke-dasharray: 1207.333984375px;
  }

  100% {
    stroke-dashoffset: 0;
    stroke-dasharray: 1207.333984375px;
  }
}

.heart2-path {
  -webkit-animation: animate-svg-stroke-5 6s cubic-bezier(0.47, 0, 0.745, 0.715) 5s both;
  animation: animate-svg-stroke-5 6s cubic-bezier(0.47, 0, 0.745, 0.715) 5s both;
}

@keyframes tilt-shaking {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(20deg);
  }
  50% {
    transform: rotate(0eg);
  }
  75% {
    transform: rotate(-20deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@-webkit-keyframes animate-svg-stroke-6 {
  0% {
    stroke-dashoffset: 1341.26025390625px;
    stroke-dasharray: 1341.26025390625px;
  }

  100% {
    stroke-dashoffset: 0;
    stroke-dasharray: 1341.26025390625px;
  }
}

@keyframes animate-svg-stroke-6 {
  0% {
    stroke-dashoffset: 1341.26025390625px;
    stroke-dasharray: 1341.26025390625px;
  }

  100% {
    stroke-dashoffset: 0;
    stroke-dasharray: 1341.26025390625px;
  }
}

.heart3-path {
  -webkit-animation: animate-svg-stroke-6 7s ease-in 4s both;
  animation: animate-svg-stroke-6 7s ease-in 4s both;
}

.vday-path {
  fill: #f9537b;
  fill-opacity: 0;
  stroke: #f9537b;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-dasharray: 860;
  stroke-dashoffset: 860;
  animation: draw 3s linear forwards;
  filter: drop-shadow(0 0 10px #f9537b);
}
.vday-path3 {
  stroke-dasharray: 1516 2250;
}

@keyframes draw {
  to {
    stroke-dashoffset: 0;
  }
}

@-webkit-keyframes draw {
  to {
    stroke-dashoffset: 0;
  }
}

@-moz-keyframes draw {
  to {
    stroke-dashoffset: 0;
  }
}
@-o-keyframes draw {
  to {
    stroke-dashoffset: 0;
  }
}
