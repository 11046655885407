html, body, #root {
    height: 100%;
}

/* Montserrat */
@font-face {
    font-family: 'Montserrat';
    src: url("../assets/fonts/Montserrat-Bold.woff2") format('woff2');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url("../assets/fonts/Montserrat-ExtraBold.woff2") format('woff2');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url("../assets/fonts/Montserrat-Light.woff2") format('woff2');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url("../assets/fonts/Montserrat-MediumItalic.woff2") format('woff2');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Montserrat';
    src: url("../assets/fonts/Montserrat-Regular.woff2") format('woff2');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url("../assets/fonts/Montserrat-SemiBold.woff2") format('woff2');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url("../assets/fonts/Montserrat-SemiBoldItalic.woff2") format('woff2');
    font-weight: 600;
    font-style: italic;
}

/* Tektur */
@font-face {
    font-family: 'Tektur';
    src: url("../assets/fonts/Tektur-Regular.woff2") format('woff2');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Tektur';
    src: url("../assets/fonts/Tektur-SemiBold.woff2") format('woff2');
    font-weight: 600;
    font-style: normal;
}


*,
*::before,
*::after {
    padding: 0;
    margin: 0;
    border: 0;
    box-sizing: border-box;
}

a {
    text-decoration: none;
}

img {
    vertical-align: top;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: inherit;
    font-size: inherit;
}

html,
body {
    line-height: 1;
    font-size: 18px;
    color: #fff!important;
    font-weight: 400;
    font-family: "Montserrat", sans-serif!important;
    position: relative;
    letter-spacing: normal!important;
    overflow-x: hidden;
}

.splide__pagination__page {
    background: #ccc;
    border: 0;
    border-radius: 26px!important;
    display: inline-block;
    height: 3px;
    margin: 3px 7px!important;
    opacity: .7;
    padding: 0;
    position: relative;
    transition: transform .2s linear;
    width: 22px;
}
.splide__pagination__page.is-active {
    background: radial-gradient(circle, #2df6de 30%, #3c5dfe 100%)!important;
    width: 40px!important;
}

.splide__pagination {
    bottom: -1.5em!important;
}

.splide {
    margin-top: 30px;
}
.visibleLabel label {
    color: #fff!important;
}
